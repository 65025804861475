import { TokenRefreshLink } from "apollo-link-token-refresh";
import { getJwtToken, getRefreshToken, setJwtToken } from "contexts/AuthProvider.tsx";
import decodeJWT, { JwtPayload } from "jwt-decode";

export function makeTokenRefreshLink() {
  return new TokenRefreshLink({
    // Indicates the current state of access token expiration
    // If token not yet expired or user doesn't have a token (guest) true should be returned
    isTokenValidOrUndefined: () => {
      const token = getJwtToken();

      // If there is no token, the user is not logged in
      // We return true here, because there is no need to refresh the token
      if (!token) return true;

      // Otherwise, we check if the token is expired
      const claims = decodeJWT(token);
      console.log("[AUTH-REFRESH]", claims);
      const expirationTimeInSeconds = claims.exp * 1000;
      const now = new Date();
      const isValid = expirationTimeInSeconds >= now.getTime();

      // Return true if the token is still valid, otherwise false and trigger a token refresh
      return isValid;
    },
    // Responsible for fetching refresh token
    fetchAccessToken: async () => {
      const jwt = decodeJWT(getJwtToken());
      const refreshToken = getRefreshToken();
      const fingerprintHash = jwt?.["https://hasura.io/jwt/claims"]?.["X-User-Fingerprint"];

      const request = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
                mutation RefreshToken($refreshToken: String!) {
                  refreshToken(refreshToken: $refreshToken) {
                    token
                    payload
                    refreshToken
                    refreshExpiresIn
                  }
                }
                `,
          variables: {
            refreshToken,
          },
        }),
      });

      return request.json();
    },
    // Callback which receives a fresh token from Response.
    // From here we can save token to the storage
    handleFetch: (accessToken) => {
      setJwtToken(accessToken);
    },
    handleResponse:
      (operation, accessTokenField) =>
      ({ data }) => {
        // here you can parse response, handle errors, prepare returned token to // further operations // returned object should be like this: // { //    access_token: 'token string here' // }
        return { access_token: data.refreshToken.token };
      },

    handleError: (err) => {
      console.warn("Your refresh token is invalid. Try to reauthenticate.");
      console.error(err);
      // Remove invalid tokens
      localStorage.removeItem("jwt");
      localStorage.removeItem("refreshToken");
    },
  });
}
