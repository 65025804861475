import { Box, Heading, Stack, StackProps, Text } from "@chakra-ui/react";

interface FieldGroupProps extends StackProps {
  title?: string;
  subtitle?: string;
}

const FieldGroup = (props: FieldGroupProps) => {
  const { title, subtitle, children, ...flexProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Stack direction={{ base: "column", md: "row" }} spacing="6" py="4" {...flexProps}>
      <Box minW="3xs">
        {title && (
          <Heading as="h2" fontWeight="semibold" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <Text fontSize="md" color="gray.500">
            {subtitle}
          </Text>
        )}
      </Box>
      {children}
    </Stack>
  );
};

export default FieldGroup;
