/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  StackDivider,
  StackProps,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import coverPNG from "assets/pablo-street-art.png";
import { FieldGroup, PasswordInput } from "components/ui";
import { useFormik } from "formik";
import { ChangePassword, ChangePasswordVariables } from "graphql/queries/types/ChangePassword";
import { GetUserById, GetUserByIdVariables } from "graphql/queries/types/GetUserById";
import {
  RequestUserResetPassword,
  RequestUserResetPasswordVariables,
} from "graphql/queries/types/RequestUserResetPassword";
import {
  ResetUserPassword,
  ResetUserPasswordVariables,
} from "graphql/queries/types/ResetUserPassword";
import {
  CHANGE_PASSWORD,
  GET_USER,
  REQUEST_PASSWORD_RESET_TOKEN,
  RESET_USER_PASSWORD,
  UPDATE_USER,
} from "graphql/queries/users.gql";
import cleanObject from "helpers/cleanObject";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import styles from "./scss/RecoverPassword.module.scss";

function validateEmail(value: string) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return { error };
}

const RecoverPasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [show, setShow] = useState(false);

  const [requestToken, { data, loading, error }] = useMutation<
    RequestUserResetPassword,
    RequestUserResetPasswordVariables
  >(REQUEST_PASSWORD_RESET_TOKEN);

  const [
    resetPassword,
    { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError },
  ] = useMutation<ResetUserPassword, ResetUserPasswordVariables>(RESET_USER_PASSWORD);

  const formikRequestPasswordResetToken = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      const errors = validateEmail(values.email);
      await requestToken({
        variables: {
          ...values,
        },
      });
    },
  });

  const formikRecoverPassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async ({ newPassword, confirmPassword }) => {
      if (!token) return;
      await resetPassword({
        variables: { newPassword, confirmPassword, resetPasswordToken: token },
      });
    },
  });

  if (token)
    return (
      <div className={styles["with-sidebar"]}>
        <div className={styles.placeholder}>
          <img src={coverPNG} alt="" className={styles.placeholder__illustration} />
        </div>
        <div className={styles.login}>
          <div>
            <Stack spacing="4" divider={<StackDivider />}>
              <form id="requestPasswordResetToken" onSubmit={formikRecoverPassword.handleSubmit}>
                <VStack width="full" spacing="6" bgColor="white" p="8">
                  {resetPasswordError && (
                    <Alert status="error">
                      <>
                        <AlertIcon />
                        {resetPasswordError.message}
                      </>
                    </Alert>
                  )}

                  {resetPasswordData && (
                    <Alert status="info">
                      <VStack alignItems="flex-start">
                        <Text>{t("We've set your new password!")}</Text>
                        <Link to="/login">{t("You can now log in")}</Link>
                      </VStack>
                    </Alert>
                  )}

                  <Heading>{t("Recover your account")}</Heading>
                  <Text>
                    {t(
                      "Enter your username or email address and we will send you instructions on how to create a new password."
                    )}
                  </Text>
                  <FormControl isRequired>
                    <FormLabel htmlFor="oldPassword">{t("Email")}</FormLabel>
                    <Input
                      width="auto"
                      id="email"
                      type="email"
                      name="email"
                      onChange={formikRecoverPassword.handleChange}
                      value={formikRecoverPassword.values.email}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="oldPassword">{t("New Password")}</FormLabel>
                    <PasswordInput
                      name="newPassword"
                      onChange={formikRecoverPassword.handleChange}
                      value={formikRecoverPassword.values.newPassword}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="oldPassword">{t("Confirm Password")}</FormLabel>
                    <PasswordInput
                      name="confirmPassword"
                      onChange={formikRecoverPassword.handleChange}
                      value={formikRecoverPassword.values.confirmPassword}
                    />
                  </FormControl>

                  <HStack width="full">
                    <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                      {t("Continue")}
                    </Button>
                  </HStack>
                </VStack>
              </form>
            </Stack>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles["with-sidebar"]}>
      <div className={styles.placeholder}>
        <img src={coverPNG} alt="" className={styles.placeholder__illustration} />
      </div>
      <div className={styles.login}>
        <div>
          <Stack spacing="4" divider={<StackDivider />}>
            <form
              id="requestPasswordResetToken"
              onSubmit={formikRequestPasswordResetToken.handleSubmit}
            >
              <VStack width="full" spacing="6" bgColor="white" p="8">
                {(error || data) && (
                  <Alert status="info">
                    <>
                      <AlertIcon />
                      {t("We've sent you an email with the instructions to recover your password!")}
                    </>
                  </Alert>
                )}

                <Heading>{t("Recover your account")}</Heading>
                <Text>
                  {t(
                    "Enter your username or email address and we will send you instructions on how to create a new password."
                  )}
                </Text>
                <FormControl isRequired>
                  <FormLabel htmlFor="oldPassword">{t("Email")}</FormLabel>
                  <Input
                    width="auto"
                    id="email"
                    type="email"
                    name="email"
                    onChange={formikRequestPasswordResetToken.handleChange}
                    value={formikRequestPasswordResetToken.values.email}
                  />
                </FormControl>

                <HStack width="full">
                  <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                    {t("Continue")}
                  </Button>
                </HStack>
              </VStack>
            </form>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default RecoverPasswordPage;
