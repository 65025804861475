import { Icon } from "@chakra-ui/react";
import { BooleanFilter } from "components/ui";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";

interface CustomColumn {
  Header: string;
  Footer: string;
  accessor: string | (() => string);
  Cell: (arg0: any) => JSX.Element;
}

const booleanCell =
  // eslint-disable-next-line react/no-unused-prop-types
  ({ cell: { value } }: { cell: { value: boolean } }) =>
    value ? (
      <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
    ) : (
      <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
    );

function getColumns<T>(
  data: T,
  customFields: string[] = [],
  customColumns: CustomColumn[] = [],
  filterableColumns: string[] = []
) {
  if (!data) throw new Error("Can't render columns without data");

  const columns = Object.keys(data)
    .filter((key) => key !== "__typename")
    .filter((key) => key !== "id")
    .filter((key) => (customFields.length > 0 ? customFields.indexOf(key) === -1 : true))
    .map((key) => {
      if (typeof data[key as keyof typeof data] === "boolean") {
        return {
          Header: key,
          Footer: key,
          accessor: key,
          Cell: booleanCell,
          Filter: BooleanFilter,
          filter: "equals",
          disableFilters: filterableColumns.length > 0 ? !filterableColumns.includes(key) : true,
        };
      }
      return {
        Header: key,
        Footer: key,
        accessor: key,
        disableFilters: filterableColumns.length > 0 ? !filterableColumns.includes(key) : true,
      };
    });

  if (!customColumns) return columns;
  return [...columns, ...customColumns];
}

export default getColumns;
