import { Icon } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import {
  BiAward,
  BiCollection,
  BiMoney,
  BiPaint,
  BiPaperclip,
  BiQuestionMark,
} from "react-icons/bi";
import { MdOutlineLocalShipping, MdOutlineMuseum, MdOutlinePolicy } from "react-icons/md";

const topMenuEntries = [
  {
    to: "/mgmt/assets",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Assets</Trans>,
  },
  {
    to: "/spin/",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Policies</Trans>,
  },
  {
    to: "/o/analysis",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Analysis</Trans>,
  },
  {
    to: "/o/users",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Users</Trans>,
  },
];

const mgmtMenuEntries = [
  {
    to: "/mgmt/assets",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>All assets</Trans>,
  },
  {
    to: "/mgmt/assets/new",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Add new asset</Trans>,
  },
  {
    to: "/mgmt/collections",
    icon: <Icon mr={3} w={6} h={6} as={BiCollection} color="white" />,
    name: <Trans>Collections</Trans>,
  },
  {
    to: "/mgmt/collections/new",
    icon: <Icon mr={3} w={6} h={6} as={BiCollection} color="white" />,
    name: <Trans>Add new collection</Trans>,
  },
  {
    to: "/mgmt/exhibitions",
    icon: <Icon mr={3} w={6} h={6} as={MdOutlineMuseum} color="white" />,
    name: <Trans>Exhibitions</Trans>,
  },
  {
    to: "/mgmt/logistic",
    icon: <Icon mr={3} w={6} h={6} as={MdOutlineLocalShipping} color="white" />,
    name: <Trans>Logistic</Trans>,
  },
  {
    to: "/mgmt/insurance",
    icon: <Icon mr={3} w={6} h={6} as={MdOutlinePolicy} color="white" />,
    name: <Trans>Insurance</Trans>,
  },
  {
    to: "/mgmt/appraisal",
    icon: <Icon mr={3} w={6} h={6} as={BiMoney} color="white" />,
    name: <Trans>Appraisal</Trans>,
  },
  {
    to: "/mgmt/cr-sr",
    icon: <Icon mr={3} w={6} h={6} as={BiAward} color="white" />,
    name: <Trans>CR/SR</Trans>,
  },
  {
    to: "/mgmt/quotation",
    icon: <Icon mr={3} w={6} h={6} as={BiQuestionMark} color="white" />,
    name: <Trans>Quotation</Trans>,
  },
  {
    to: "/mgmt/docs",
    icon: <Icon mr={3} w={6} h={6} as={BiPaperclip} color="white" />,
    name: <Trans>Documentation</Trans>,
  },
];

const spinMenuEntries = [
  {
    to: "/spin/te-policies",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>TE Policies</Trans>,
  },
  {
    to: "/spin/te-certs",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>TE Certificates</Trans>,
  },
  {
    to: "/spin/pc-policies",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>PC Policies</Trans>,
  },
  {
    to: "/spin/pc-certs",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>PC Certificates</Trans>,
  },
];

const genericMenuEntries = [
  {
    to: "/o/analysis",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Analysis</Trans>,
  },
  {
    to: "/o/registry",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Registry</Trans>,
  },
  {
    to: "/o/users",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="white" />,
    name: <Trans>Users</Trans>,
  },
];

export { genericMenuEntries, mgmtMenuEntries, spinMenuEntries, topMenuEntries };
