/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FieldGroup } from "components/ui";
import { useFormik } from "formik";
import { CreateUser, CreateUserVariables } from "graphql/queries/types/CreateUser";
import {
  getAllPermission,
  getAllPermission_allPermissions_edges,
  getAllPermission_allPermissions_edges_node,
} from "graphql/queries/types/getAllPermission";
import {
  UpdateUserPermissions,
  UpdateUserPermissionsVariables,
} from "graphql/queries/types/UpdateUserPermissions";
import { CREATE_USER, GET_ALL_PERMISSIONS, UPDATE_PERMISSIONS } from "graphql/queries/users.gql";
import cleanObject from "helpers/cleanObject";
import countryCodes from "helpers/countryCodes.json";
import currencies from "helpers/currencies.json";
import { Dictionary } from "lodash";
import groupBy from "lodash/groupBy";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import PermissionsForm from "../PermissionsForm";

const UsersNewPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signatureUploadRef = useRef<HTMLInputElement>(null);
  const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

  const [createUser, { data: createUserData, loading: createUserLoading, error: createUserError }] =
    useMutation<CreateUser, CreateUserVariables>(CREATE_USER);
  const [formattedPermissions, setFormattedPermissions] =
    useState<Dictionary<(getAllPermission_allPermissions_edges | null)[]>>();

  const formikCreateUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      phone: "",
      country: "",
      currency: "",
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { password, email, ...userData } = cleanObject(values);
      await createUser({
        variables: {
          email,
          userData,
        },
      });
      // setTimeout(() => navigate("/o/users", { replace: true }), 5000);
    },
  });

  const {
    data: allPermissions,
    loading: allPermissionsLoading,
    error: allPermissionsError,
  } = useQuery<getAllPermission>(GET_ALL_PERMISSIONS, {
    onCompleted(permissionData) {
      if (!permissionData?.allPermissions?.edges) return;

      const {
        allPermissions: { edges },
      } = permissionData;

      const groupedPermissions = groupBy(
        edges,
        (permission) => permission?.node?.contentType.model
      );
      console.log({ groupedPermissions });
      setFormattedPermissions(groupedPermissions);
    },
  });

  const [
    updateUserPermissions,
    {
      data: updateUserPermissionsData,
      loading: updateUserPermissionsLoading,
      error: updateUserPermissionsError,
    },
  ] = useMutation<UpdateUserPermissions, UpdateUserPermissionsVariables>(UPDATE_PERMISSIONS);

  const formikUpdatePermissions = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...allPermissions,
    },
    onSubmit: async () => {
      await updateUserPermissions({
        variables: {
          email: createUserData?.createUser?.user?.email ?? "",
          permissions: allowedPermissions,
        },
      });
    },
  });

  return (
    <Box maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}>
        <form id="createUser" onSubmit={formikCreateUser.handleSubmit}>
          <FieldGroup title={t("Personal Info")} subtitle={t("Lorem ipsum dolor sit amet")}>
            <VStack
              width="full"
              spacing="6"
              bgColor="white"
              p="8"
              rounded="lg"
              shadow="sm"
              align="left"
            >
              <FormControl>
                <FormLabel htmlFor="email">{t("Email Address")}</FormLabel>
                <Input
                  width="auto"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formikCreateUser.handleChange}
                  value={formikCreateUser.values.email}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="name">{t("Name")}</FormLabel>
                <Input
                  width="auto"
                  id="name"
                  name="name"
                  onChange={formikCreateUser.handleChange}
                  value={formikCreateUser.values.name}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="phone">{t("Phone number")}</FormLabel>
                <Input
                  width="auto"
                  id="phone"
                  name="phone"
                  type="phone"
                  onChange={formikCreateUser.handleChange}
                  value={formikCreateUser.values.phone ?? ""}
                />
              </FormControl>

              <Stack
                direction="column"
                spacing="6"
                align="center"
                width="full"
                bgColor="white"
                rounded="lg"
                shadow="sm"
              >
                <FormControl>
                  <FormLabel htmlFor="country">{t("Country")}</FormLabel>
                  <Select
                    width="auto"
                    id="country"
                    name="country"
                    onChange={formikCreateUser.handleChange}
                    value={formikCreateUser.values?.country ?? ""}
                  >
                    {countryCodes.map(({ name, code }) => (
                      <option value={code}>{name}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="currency">{t("Currency")}</FormLabel>
                  <Select
                    width="auto"
                    id="currency"
                    name="currency"
                    onChange={formikCreateUser.handleChange}
                    value={formikCreateUser.values.currency ?? ""}
                  >
                    {currencies.map(({ name, value }) => (
                      <option value={value} selected={formikCreateUser.values.currency === value}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Divider borderColor="gray.100" />
                {createUserError && (
                  <Alert status="error">
                    <>
                      <AlertIcon />
                      {createUserError.message}
                    </>
                  </Alert>
                )}
                {createUserData && (
                  <Alert status="success">
                    <>
                      <AlertIcon />
                      <VStack alignItems="flex-start">
                        <Text>
                          {t(
                            `We've sent an email to ${formikCreateUser.values.email} with instructions to log in!`
                          )}
                        </Text>
                        <HashLink to="#permissions">
                          <Text variant="muted" fontSize="sm" align="left">
                            {t(
                              "In the meantime, you can add permission for this user, click here to go to permissions."
                            )}
                          </Text>
                        </HashLink>
                      </VStack>
                    </>
                  </Alert>
                )}
                <HStack width="full">
                  <Button
                    size="sm"
                    isLoading={createUserLoading}
                    loadingText="Loading"
                    type="submit"
                  >
                    {t("Save Changes")}
                  </Button>
                  <Button size="sm" variant="danger">
                    {t("Cancel")}
                  </Button>
                </HStack>
              </Stack>
            </VStack>
          </FieldGroup>
        </form>
        {createUserData && (
          <div id="permissions">
            <PermissionsForm
              handleSubmit={formikUpdatePermissions.handleSubmit}
              success={!!updateUserPermissionsData}
              permissions={formattedPermissions}
              loading={updateUserPermissionsLoading}
              error={updateUserPermissionsError}
              allowedPermissions={allowedPermissions}
              setAllowedPermissions={setAllowedPermissions}
            />
          </div>
        )}
        {/* <FieldGroup title={t("Signature")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <Stack
            direction="column"
            spacing="6"
            align="center"
            width="full"
            bgColor="white"
            p="8"
            rounded="lg"
            shadow="sm"
          >
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BiFile} />
                </InputLeftElement>
                <input
                  type="file"
                  onChange={formikUpdateSignature.handleChange}
                  accept="image/*"
                  name="signature"
                  ref={signatureUploadRef}
                  style={{ display: "none" }}
                />
                <Input
                  placeholder="Your file ..."
                  onClick={(): void => {
                    if (!signatureUploadRef || !signatureUploadRef.current) return;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    signatureUploadRef.current.click();
                  }}
                  // onChange={(e) => {}}
                  readOnly
                  value={formikUpdateSignature.values?.signature}
                />
              </InputGroup>
            </FormControl>
            <HStack width="full">
              <Button size="sm" isLoading={false} loadingText="Loading" type="submit">
                {t("Upload new signature")}
              </Button>
              <Button size="sm" variant="danger">
                {t("Remove old signature")}
              </Button>
            </HStack>
          </Stack>
        </FieldGroup> */}
        {/* <form id="updateUser" onSubmit={formikChangePassword.handleSubmit}>
          <FieldGroup title={t("Change Password")} subtitle="Lorem ipsum dolor sit amet">
            <VStack width="full" spacing="6" bgColor="white" p="8" rounded="lg" shadow="sm">
              {changePasswordError && (
                <Alert status="error">
                  <>
                    <AlertIcon />
                    {changePasswordError.message}
                  </>
                </Alert>
              )}
              {changePasswordData && (
                <Alert status="success">
                  <>
                    <AlertIcon />
                    {t("Your password has been updated")}
                  </>
                </Alert>
              )}

              <FormControl isRequired>
                <FormLabel htmlFor="oldPassword">{t("Old Password")}</FormLabel>
                <Input
                  width="auto"
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  onChange={formikChangePassword.handleChange}
                  value={formikChangePassword.values.oldPassword}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="newPassword">{t("New password")}</FormLabel>
                <Input
                  width="auto"
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  onChange={formikChangePassword.handleChange}
                  value={formikChangePassword.values.newPassword}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="confirmPassword">{t("Confirm Password")}</FormLabel>
                <Input
                  width="auto"
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formikChangePassword.handleChange}
                  value={formikChangePassword.values.confirmPassword}
                />
              </FormControl>
              <Divider borderColor="gray.100" />
              <HStack width="full">
                <Button
                  size="sm"
                  isLoading={changePasswordLoading}
                  loadingText="Loading"
                  type="submit"
                >
                  {t("Save Changes")}
                </Button>
                <Button size="sm" variant="danger">
                  {t("Cancel")}
                </Button>
              </HStack>
            </VStack>
          </FieldGroup>
        </form> */}
      </Stack>
    </Box>
  );
};

export default UsersNewPage;
