/* eslint-disable @typescript-eslint/no-misused-promises */
import "./App.css";

import { ApolloClient, ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { AuthProvider } from "contexts";
import theme from "globalStyles/theme";
import { useApollo } from "graphql/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routing } from "routes";

interface I18NNamespace {
  pages: {
    hello: string;
  };
  common: string;
}

function App() {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const client: ApolloClient<unknown> = useApollo();

  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n
      .changeLanguage(language)
      .then((d) => d)
      .catch((e) => console.log(e));
  };

  // useEffect(() => {
  //   window.addEventListener("storage", (event) => {
  //     // When local storage changes, dump the list to
  //     // the console.
  //     if (event.key === "logout") {
  //       console.log("logged out from storage!");
  //       navigate("/login", { replace: true });
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   try {
  //     if (typeof window !== "undefined") {
  //       if (!sessionStorage.length) {
  //         // Ask other tabs for session storage
  //         // console.log("Calling getSessionStorage");
  //         localStorage.setItem("getSessionStorage", String(Date.now()));
  //       }

  //       if (!sessionStorage.getItem("jwtToken")) {
  //         // console.log("Calling getSessionStorage");
  //         localStorage.setItem("getSessionStorage", String(Date.now()));
  //       }

  //       const jwtToken: unknown = sessionStorage.getItem("jwtToken");
  //       // console.log(jwtToken);
  //       if (!jwtToken) {
  //         // console.log("Calling getSessionStorage");
  //         localStorage.setItem("getSessionStorage", String(Date.now()));
  //       }

  //       window.addEventListener("storage", (event) => {
  //         // console.log("storage event", event);
  //         if (event.key === "getSessionStorage") {
  //           // Some tab asked for the sessionStorage -> send it
  //           localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
  //           localStorage.removeItem("sessionStorage");
  //         } else if (
  //           (event.key === "sessionStorage" && !sessionStorage.length) ||
  //           !sessionStorage.getItem("jwtToken")
  //         ) {
  //           // sessionStorage is empty -> fill it
  //           if (!event.newValue) return;
  //           // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //           const data = JSON.parse(event.newValue);

  //           for (const key in data) {
  //             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //             sessionStorage.setItem(key, data[key] as string);
  //           }
  //         }
  //       });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <Routing setCurrentLocale={changeLanguage} />
        </AuthProvider>
      </ApolloProvider>
    </ChakraProvider>
  );
}

export default App;
