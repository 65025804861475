import { useEffect, useMemo, useState } from "react";
import { Button } from "@chakra-ui/react";

const BooleanFilter = ({ title, column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const [isEnabled, setIsEnabled] = useState(null);
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  useEffect(() => {
    console.log(id, isEnabled);
    setFilter(isEnabled);
  }, [isEnabled]);
  // Render a multi-select box
  return (
    <>
      {/* // <select
    //   value={filterValue}
    //   onChange={(e) => {
    //     setFilter(e.target.value || undefined);
    //   }}
    // >
    //   <option value="">All</option>
    //   {options.map((option, i) => (
    //     <option key={i} value={option}>
    //       {option}
    //     </option>
    //   ))}
    // </select>
  */}
      <Button
        mr={2}
        size="sm"
        bgColor={isEnabled ? "black" : "white"}
        color={isEnabled ? "white" : "black"}
        onClick={() => setIsEnabled((prevState) => (prevState === true ? null : true))}
      >
        {title ?? id}
      </Button>
    </>
  );
};

export default BooleanFilter;
