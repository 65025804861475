/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { RiDeleteBinLine, RiEditLine, RiEyeLine } from "react-icons/ri";
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import {
  Badge,
  Box,
  ButtonGroup,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverFooter,
  PopoverBody,
} from "@chakra-ui/react";
import { Pagination } from "./Pagination";
import styles from "./scss/index.module.scss";
import { Link } from "react-router-dom";
import { GlobalFilter, fuzzyTextFilterFn, DefaultColumnFilter } from "./GlobalFilter";
import TableCSVDownload from "./ExportCsv";
import { BiChevronDown } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";
import { updateCall } from "typescript";

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (!resolvedRef || !resolvedRef?.current) return;
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const renderViewCell = (pathname, action, canDoAction) => ({
  id: "viewButton",
  accessor: "id",
  Filter: <span></span>,
  disableFilters: true,
  Cell: ({ row: { original } }) => (
    <Link to={`${pathname}${original?.id}`}>
      <Button onClick={action} disabled={!canDoAction} size="sm" variant="ghost">
        <RiEyeLine />
      </Button>
    </Link>
  ),
});
const renderEditCell = (pathname, action, canDoAction) => ({
  id: "editButton",
  accessor: "id1",
  Filter: <span></span>,
  disableFilters: true,
  Cell: ({ row: { original } }) => (
    <Link to={`${pathname}edit/${original?.id}`}>
      <Button onClick={action} disabled={!canDoAction} size="sm" variant="ghost">
        <RiEditLine />
      </Button>
    </Link>
  ),
});

const renderDeleteCell = (pathname, action, canDoAction, deleteActionLoading) => {
  const { t } = useTranslation();
  return {
    id: "deleteButton",
    accessor: "id2",
    Filter: <span></span>,
    disableFilters: true,
    Cell: ({ row: { original } }) => {
      return (
        <Popover>
          <PopoverTrigger>
            <Button
              colorScheme="red"
              variant="ghost"
              loading={deleteActionLoading}
              // onClick={() => action(original?.id)}
              disabled={!canDoAction}
              size="sm"
            >
              <RiDeleteBinLine />
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
              <PopoverHeader pt={4} fontWeight="bold" border="0">
                {t("Are you sure you want to delete it?")}
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>{t("Warning message")}</PopoverBody>
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <ButtonGroup size="sm">
                  <Button
                    variant="danger"
                    isLoading={deleteActionLoading}
                    onClick={() => action(original?.id)}
                  >
                    {t("Delete it")}
                  </Button>
                  <Button>{t("Cancel")}</Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      );
    },
  };
};

const Table = ({
  pathname,
  data,
  columns,
  viewAction = () => null,
  canView = true,
  editAction = () => null,
  canEdit = true,
  deleteAction,
  deleteActionLoading = false,
  canDelete = true,
  batchActions = [],
}) => {
  if (!data) return null;
  if (!columns) return null;
  const [filters, setFilters] = useState([]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // const defaultColumn = React.useMemo(
  //   () => ({
  //     // Let's set up our default Filter UI
  //     Filter: DefaultColumnFilter,
  //   }),
  //   []
  // );

  const columnsWithActions = [
    renderViewCell(pathname, viewAction, canView),
    renderEditCell(pathname, editAction, canEdit),
    renderDeleteCell(pathname, deleteAction, canDelete, deleteActionLoading),
  ];

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    setFilter,
    state: { pageIndex, pageSize, globalFilter, filters: selectedFilters },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        // {
        //   Header: "ID",
        //   Footer: "ID",
        //   accessor: "id",
        //   Cell: ({ row: { original } }) => <Badge>{original?.id.slice(0, 5)}...</Badge>,
        // },
        ...columns,
        ...columnsWithActions,
      ]);
    }
  );

  useEffect(() => {
    headerGroups.map((headerGroup) =>
      headerGroup.headers.map((column) => {
        const columnFilter = column.canFilter
          ? column.render("Filter", {
              column,
              title: <Trans>Only {column.id}</Trans>,
            })
          : null;

        setFilters((prevState) => [...prevState, columnFilter]);
      })
    );
  }, []);

  useEffect(() => console.log({ filters }), [filters]);
  return (
    <>
      <HStack justifyContent={"flex-start"} spacing="4">
        Filters: {filters}
        <Box w={"30%"} py={4} px="8" justifyContent="flex-end">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Box>
      </HStack>
      <Box border="1px solid" borderColor="gray.100" borderRadius="lg" bgColor={"white"} flex="1">
        <HStack justifyContent={"space-between"}>
          <Box py={4} px="8">
            <Heading fontSize={"lg"}>Title</Heading>
          </Box>
          <HStack>
            <Box py={4} px="4" justifyContent="flex-end">
              <TableCSVDownload data={rows} columns={columns} />
            </Box>
            <Box py={4} px="4" justifyContent="flex-end">
              {batchActions && batchActions.length > 0 && (
                <Menu>
                  <MenuButton size="sm" as={Button} rightIcon={<BiChevronDown />}>
                    Actions
                  </MenuButton>
                  <MenuList>
                    {batchActions.map(({ name, action }) => {
                      return <MenuItem onClick={() => action(selectedFlatRows)}>{name}</MenuItem>;
                    })}
                  </MenuList>
                </Menu>
              )}
            </Box>
          </HStack>
        </HStack>

        <ChakraTable {...getTableProps()} size={"sm"}>
          <Thead className="">
            {headerGroups.map((headerGroup) => (
              <>
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <>
                      <Th py={2} {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {/* <div>
                          {column.canFilter &&
                            filters.push(
                              column.render("Filter", {
                                column,
                                title: <Trans>Only {column.id}</Trans>,
                              })
                            )}
                        </div> */}
                        {column.render("Header")}
                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                      </Th>
                    </>
                  ))}
                </Tr>
              </>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            {/* {footerGroups.map((group) => (
              <Tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <Td {...column.getFooterProps()}>{column.render("Footer")}</Td>
                ))}
              </Tr>
            ))} */}
          </Tfoot>
        </ChakraTable>
      </Box>

      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default Table;
