import { useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import { DropdownFilter, Table } from "components/ui";
import { GET_ALL_USERS } from "graphql/queries";
import {
  GetAllUsers,
  GetAllUsers_allUsers_edges,
  GetAllUsers_allUsers_edges_node,
} from "graphql/queries/types/GetAllUsers";
import {
  ToggleUserIsActive,
  ToggleUserIsActiveVariables,
} from "graphql/queries/types/ToggleUserIsActive";
import { TOGGLE_USER_IS_ACTIVE } from "graphql/queries/users.gql";
import getColumns from "helpers/getColumns";
import { MutableRefObject, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiPlusCircle } from "react-icons/bi";
import { Link } from "react-router-dom";

const CUSTOM_FIELDS = ["userType", "country"];
const CUSTOM_COLUMNS = [
  {
    Header: "User Type",
    Footer: "User Type",
    accessor: "userType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "country.code",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
  },
];
const BATCH_ACTIONS = [
  {
    name: <Trans>Disable selected users</Trans>,
    action: (selectedRows: GetAllUsers_allUsers_edges_node) => console.log(selectedRows),
  },
];
const FILTERABLE_COLUMNS = ["isActive", "status"];

const UsersPage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery<GetAllUsers>(GET_ALL_USERS);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<FocusableElement>;

  const [
    toggleIsActiveUser,
    {
      data: toggleIsActiveUserData,
      loading: toggleIsActiveUserLoading,
      error: toggleIsActiveUserError,
    },
  ] = useMutation<ToggleUserIsActive, ToggleUserIsActiveVariables>(TOGGLE_USER_IS_ACTIVE);

  if (loading) return null;
  if (error) return <p>{error.toString()}</p>;

  if (!data || !data.allUsers || data.allUsers.edges.length === 0) return null;

  const {
    allUsers: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns: GetAllUsers_allUsers_edges_node = edges[0].node;
  const columns = getColumns(dataToGetColumns, CUSTOM_FIELDS, CUSTOM_COLUMNS, FILTERABLE_COLUMNS);

  const onDelete = async (id: string) => {
    await toggleIsActiveUser({
      variables: {
        id,
      },
    });

    await refetch();
  };

  return (
    <Box>
      <Box py={4}>
        <Heading size="sm" color="gray.500">
          Quick actions
        </Heading>
        <HStack mt={2}>
          <Link to="new">
            <Button leftIcon={<BiPlusCircle />} size="sm" borderColor="primaryFuchsia">
              <Trans>Add new user </Trans>
            </Button>
          </Link>
          <Link to="invite">
            <Button leftIcon={<BiPlusCircle />} size="sm" borderColor="primaryFuchsia">
              <Trans>Invite user</Trans>
            </Button>
          </Link>
        </HStack>
      </Box>
      <Divider borderColor="gray.100" />

      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allUsers.edges.flatMap((edge) => edge?.node)}
          pathname="/o/users/"
          deleteAction={(id: string) => onDelete(id)}
          deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default UsersPage;
