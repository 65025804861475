import { Callout, FormGroup, H1, Intent } from "@blueprintjs/core";
import {
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  VStack,
} from "@chakra-ui/react";
import coverPNG from "assets/pablo-street-art.png";
import { LanguageSelector } from "components/ui";
import { useAuth } from "contexts/AuthProvider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEye2Line, RiEyeCloseFill } from "react-icons/ri";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import styles from "./scss/Login.module.scss";

type LocationProps = {
  state: {
    from: Location;
  };
};

interface LoginPageProps {
  onLocaleChange?: (locale: string) => void;
}

const LoginPage = ({ onLocaleChange }: LoginPageProps) => {
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const { t } = useTranslation();
  const { jwtToken, signin, error, loading } = useAuth();
  const [show, setShow] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (jwtToken) navigate(from, { replace: true });
  }, [jwtToken]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    signin(username, password, () => {
      if (!error) {
        localStorage.removeItem("getSessionStorage");
        navigate(from, { replace: true });
      } // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
    });
  }

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      // When local storage changes, dump the list to
      // the console.
      if (event.key === "logout") {
        console.warn("[AUTH] logged out from storage!");
        navigate("/login", { replace: true });
      }
    });
  }, []);

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        const jwtTokenFromSession: unknown = sessionStorage.getItem("jwtToken");
        const logoutToken: unknown = localStorage.getItem("logout");

        if (!sessionStorage.length || !jwtTokenFromSession || !logoutToken) {
          // Ask other tabs for session storage
          console.log(
            "Calling getSessionStorage",
            !sessionStorage.length || !jwtTokenFromSession || !logoutToken,
            !sessionStorage.length,
            !jwtTokenFromSession,
            !logoutToken
          );

          localStorage.setItem("getSessionStorage", String(Date.now()));
        }

        window.addEventListener("storage", (event) => {
          if (event.key === "logout") {
            sessionStorage.clear();
            localStorage.clear();
            return;
          }
          // console.log("storage event", event);
          if (event.key === "getSessionStorage") {
            // Some tab asked for the sessionStorage -> send it
            localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
            console.log("setting");
            localStorage.removeItem("sessionStorage");
          } else if (
            (event.key === "sessionStorage" && !sessionStorage.length) ||
            !sessionStorage.getItem("jwtToken")
          ) {
            localStorage.removeItem("getSessionStorage");
            // sessionStorage is empty -> fill it
            if (!event.newValue) return;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const data = JSON.parse(event.newValue);

            // eslint-disable-next-line guard-for-in
            for (const key in data) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              sessionStorage.setItem(key, data[key] as string);
            }
            navigate(from, { replace: true });
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onLanguageSelect = (e: React.FormEvent<HTMLSelectElement>): void => {
    const {
      currentTarget: { value },
    } = e;

    if (!onLocaleChange) return;
    onLocaleChange(value);
  };

  return (
    <div className={styles["with-sidebar"]}>
      <div className={styles.login}>
        <Flex bg="white" justify="center" align="center" px="16" py="12" rounded="lg" shadow="lg">
          <div>
            <div className={styles.login__language}>
              <LanguageSelector onLanguageSelect={onLanguageSelect} />
            </div>
            {from && <H1>{t("Log in")}</H1>}
            {error && <Callout intent={Intent.DANGER}>{t("Si è verificato un errore")}</Callout>}

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <form onSubmit={handleSubmit} className={styles.login__form}>
              <VStack align="flex-start">
                <FormGroup
                  helperText=""
                  label={t("Username/email")}
                  labelFor="username"
                  labelInfo="(required)"
                >
                  <Input id="username" name="username" />
                </FormGroup>
                <FormGroup
                  helperText=""
                  label={t("Password")}
                  labelFor="password"
                  labelInfo="(required)"
                >
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      id="password"
                      name="password"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="show"
                        icon={show ? <RiEye2Line /> : <RiEyeCloseFill />}
                        size="sm"
                        variant="ghost"
                        onClick={() => setShow((prevState) => !prevState)}
                      >
                        {show ? "Hide" : "Show"}
                      </IconButton>
                    </InputRightElement>
                  </InputGroup>
                </FormGroup>
                <Link as={RouterLink} to="/recover-password" color="primaryFuchsia">
                  {t("Forgot your password?")}
                </Link>
                <Button isLoading={loading} type="submit" my={2}>
                  {t("Log in")}
                </Button>
              </VStack>
            </form>
          </div>
        </Flex>
      </div>
      <div className={styles.placeholder}>
        <img src={coverPNG} alt="" className={styles.placeholder__illustration} />
      </div>
    </div>
  );
};

export default LoginPage;
