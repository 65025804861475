import { Layout } from "layouts";
import RequireAuth from "middleware/RequireAuth";
import { LoginPage, NotFoundPage, RecoverPasswordPage } from "pages";
import NotFoundInternalPage from "pages/notFound/NotFoundInternalPage";
import OnboardPage from "pages/onboard";
import UsersPage from "pages/users";
import UsersEditPage from "pages/users/edit";
import UsersInvitePage from "pages/users/invite";
import UsersNewPage from "pages/users/new";
import { Routes, useRoutes } from "react-router-dom";
import { createRoutesFromChildren, Route } from "use-react-router-breadcrumbs";

function ProtectedPage() {
  return <h3>Protected</h3>;
}

interface RoutingProps {
  setCurrentLocale?: (locale: string) => void;
}

const ProtectedRoutes = () => (
  <>
    <Route path="/mgmt/assets" element={<ProtectedPage />} breadcrumb="Assets" />
    <Route path="/mgmt/collections" element={<ProtectedPage />} />
    <Route path="/mgmt/exhibitions" element={<ProtectedPage />} />
    <Route path="/mgmt/logistic" element={<ProtectedPage />} />
    <Route path="/mgmt/insurance" element={<ProtectedPage />} />
    <Route path="/mgmt/appraisal" element={<ProtectedPage />} />
    <Route path="/mgmt/cr-sr" element={<ProtectedPage />} />
    <Route path="/mgmt/quotation" element={<ProtectedPage />} />
    <Route path="/mgmt/docs" element={<ProtectedPage />} />
    <Route path="/spin/te-policies" element={<ProtectedPage />} />
    <Route path="/spin/te-certs" element={<ProtectedPage />} />
    <Route path="/spin/pc-policies" element={<ProtectedPage />} />
    <Route path="/spin/pc-certs" element={<ProtectedPage />} />
    <Route path="/o/analysis" element={<ProtectedPage />} />
    <Route path="/o/registry" element={<ProtectedPage />} />
    <Route path="/o/users" element={<UsersPage />} />
    <Route path="/o/users/edit/:userId" element={<UsersEditPage />} breadcrumb="Edit user" />
    <Route path="/o/users/invite" element={<UsersInvitePage />} breadcrumb="Invite user" />
    <Route path="/o/users/new" element={<UsersNewPage />} breadcrumb="Add user" />
    <Route path="*" element={<NotFoundInternalPage />} />
  </>
);
const AppRoutes = ProtectedRoutes();
export const appRouteObjects = createRoutesFromChildren(AppRoutes);

const Routing = ({ setCurrentLocale }: RoutingProps) => {
  const protectedRoutesGenerated = useRoutes(appRouteObjects);

  if (!protectedRoutesGenerated) return null;

  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLocaleChange={setCurrentLocale} />} />
      <Route path="/recover-password" element={<RecoverPasswordPage />} />
      <Route path="/recover-password/:token" element={<RecoverPasswordPage />} />
      <Route path="/onboard/:token" element={<OnboardPage />} />
      <Route element={<Layout onLocaleChange={setCurrentLocale} />} breadcrumb="Home">
        <Route path="/*" element={<RequireAuth>{protectedRoutesGenerated}</RequireAuth>} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routing;
