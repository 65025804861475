import { ApolloError } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FieldGroup } from "components/ui";
import { getAllPermission_allPermissions_edges } from "graphql/queries/types/getAllPermission";
import { Dictionary } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import IndeterminateCheckbox from "./IndeterminateCheckbox";

interface PermissionsFormProps {
  handleSubmit?: () => void;
  loading: boolean;
  error: ApolloError | undefined;
  success: boolean;
  permissions: Dictionary<(getAllPermission_allPermissions_edges | null)[]> | undefined;
  allowedPermissions: string[];
  setAllowedPermissions: Dispatch<SetStateAction<string[]>>;
}

// TODO: This component can/must be generalized to handle a form comprised of only checkboxes
// at this moment it can only handle permissions
const PermissionsForm = ({
  handleSubmit,
  loading,
  error,
  permissions,
  allowedPermissions,
  setAllowedPermissions,
  success,
}: PermissionsFormProps) => {
  const { t } = useTranslation();

  return (
    <form id="updateUser" onSubmit={handleSubmit}>
      <FieldGroup title={t("Permissions")} subtitle="Lorem ipsum dolor sit amet">
        <VStack width="full" spacing="6" bgColor="white" rounded="lg" shadow="sm">
          <Grid templateColumns="repeat(6, 1fr)" gap={6} w="100%">
            <GridItem w="100%">
              <Text fontSize="lg" fontWeight="bold">
                {t("Object")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text fontSize="lg" fontWeight="bold">
                {t("Global")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text fontSize="lg" fontWeight="bold">
                {t("Add")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text fontSize="lg" fontWeight="bold">
                {t("Update")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text fontSize="lg" fontWeight="bold">
                {t("Delete")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text fontSize="lg" fontWeight="bold">
                {t("View")}
              </Text>
            </GridItem>
          </Grid>
          {permissions &&
            Object.keys(permissions).map((permissionGroupKey) => (
              <IndeterminateCheckbox
                objectPermission={
                  permissions[permissionGroupKey] as getAllPermission_allPermissions_edges[]
                }
                allowedPermissions={allowedPermissions}
                setAllowedPermissions={setAllowedPermissions}
                permissionGroupKey={permissionGroupKey}
              />
            ))}

          <Divider borderColor="gray.100" />
          {handleSubmit && (
            <HStack width="full">
              <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                {t("Save Changes")}
              </Button>
              <Button size="sm" variant="danger">
                {t("Cancel")}
              </Button>
            </HStack>
          )}
          {error && (
            <Alert status="error">
              <>
                <AlertIcon />
                {error.message}
              </>
            </Alert>
          )}
          {success && (
            <Alert status="success">
              <>
                <AlertIcon />
                {t("Your permissions have been updated")}
              </>
            </Alert>
          )}
        </VStack>
      </FieldGroup>
    </form>
  );
};

export default PermissionsForm;
