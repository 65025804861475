/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FieldGroup } from "components/ui";
import { useFormik } from "formik";
import {
  getAllPermission,
  getAllPermission_allPermissions_edges,
  getAllPermission_allPermissions_edges_node,
} from "graphql/queries/types/getAllPermission";
import { InviteUser, InviteUserVariables } from "graphql/queries/types/InviteUser";
import {
  UpdateUserPermissions,
  UpdateUserPermissionsVariables,
} from "graphql/queries/types/UpdateUserPermissions";
import {
  CREATE_USER,
  GET_ALL_PERMISSIONS,
  INVITE_USER,
  UPDATE_PERMISSIONS,
} from "graphql/queries/users.gql";
import cleanObject from "helpers/cleanObject";
import countryCodes from "helpers/countryCodes.json";
import currencies from "helpers/currencies.json";
import { Dictionary } from "lodash";
import groupBy from "lodash/groupBy";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import PermissionsForm from "../PermissionsForm";

const UsersInvitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signatureUploadRef = useRef<HTMLInputElement>(null);
  const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

  const [createUser, { data: createUserData, loading: createUserLoading, error: createUserError }] =
    useMutation<InviteUser, InviteUserVariables>(INVITE_USER);
  const [formattedPermissions, setFormattedPermissions] =
    useState<Dictionary<(getAllPermission_allPermissions_edges | null)[]>>();

  const {
    data: allPermissions,
    loading: allPermissionsLoading,
    error: allPermissionsError,
  } = useQuery<getAllPermission>(GET_ALL_PERMISSIONS, {
    onCompleted(permissionData) {
      if (!permissionData?.allPermissions?.edges) return;

      const {
        allPermissions: { edges },
      } = permissionData;

      const groupedPermissions = groupBy(
        edges,
        (permission) => permission?.node?.contentType.model
      );
      console.log({ groupedPermissions });
      setFormattedPermissions(groupedPermissions);
    },
  });

  const [
    updateUserPermissions,
    {
      data: updateUserPermissionsData,
      loading: updateUserPermissionsLoading,
      error: updateUserPermissionsError,
    },
  ] = useMutation<UpdateUserPermissions, UpdateUserPermissionsVariables>(UPDATE_PERMISSIONS);

  const formikInviteUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      permissions: [],
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { password, email, ...userData } = cleanObject(values);
      await createUser({
        variables: {
          email,
          permissions: allowedPermissions,
        },
      });
      // setTimeout(() => navigate("/o/users", { replace: true }), 5000);
    },
  });
  return (
    <Box maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}>
        <form id="createUser" onSubmit={formikInviteUser.handleSubmit}>
          <VStack px="0">
            <FieldGroup
              title={t("Personal Info")}
              subtitle={t("Lorem ipsum dolor sit amet")}
              width="full"
              spacing="6"
              bgColor="white"
              py="8"
              rounded="lg"
              align="left"
            >
              <FormControl>
                <FormLabel htmlFor="email">{t("Email Address")}</FormLabel>
                <Input
                  width="auto"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formikInviteUser.handleChange}
                  value={formikInviteUser.values.email}
                />
              </FormControl>
            </FieldGroup>
            <Box w="100%">
              <PermissionsForm
                success={!!updateUserPermissionsData}
                permissions={formattedPermissions}
                loading={updateUserPermissionsLoading}
                error={updateUserPermissionsError}
                allowedPermissions={allowedPermissions}
                setAllowedPermissions={setAllowedPermissions}
              />
            </Box>

            <FieldGroup width="full" spacing="6" bgColor="white" rounded="lg">
              <VStack width="full" align="flex-start" justify="flex-start">
                {createUserError && (
                  <Alert status="error">
                    <>
                      <AlertIcon />
                      {createUserError.message}
                    </>
                  </Alert>
                )}
                {createUserData && (
                  <Alert status="success">
                    <>
                      <AlertIcon />
                      <VStack alignItems="flex-start">
                        <Text>
                          {t(
                            `We've sent an email to ${formikInviteUser.values.email} with instructions to log in!`
                          )}
                        </Text>
                      </VStack>
                    </>
                  </Alert>
                )}
                <HStack>
                  <Button
                    size="sm"
                    isLoading={createUserLoading}
                    loadingText="Loading"
                    type="submit"
                  >
                    {t("Save Changes")}
                  </Button>
                  <Button size="sm" variant="danger">
                    {t("Cancel")}
                  </Button>
                </HStack>
              </VStack>
            </FieldGroup>
          </VStack>
        </form>
      </Stack>
    </Box>
  );
};

export default UsersInvitePage;
