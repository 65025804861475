import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

const brandColors = {
  primaryYellow: "#FFCF21",
  primaryGreen: "#328D2E",
  primaryFuchsia: "#E72B78",
  primaryGrey: "#50535A",
  pastelPrimaryYellow: "#fff9e5",
  pastelPrimaryGreen: "#d6e8d5",
  pastelPrimaryFuchsia: "#fad4e4",
};

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        // fontFamily: "-apple-system",
      },
      table: {
        boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
        // borderColor: "gray.200",
        // borderLeftWidth: "1px",
        // borderRightWidth: "1px",
        // backgroundColor: "white",
        // borderRadius: "1rem",
        // color: "black",
      },
      // tfoot: {
      //   borderRadius: "1rem",
      // },
      // thead: {
      //   borderRadius: "1rem",
      //   padding: "1rem",
      //   backgroundColor: "black",
      // },
    },
  },
  colors: {
    ...brandColors,
    brand: {
      100: "#66001a",
      200: "#85002e",
      300: "#a50045",
      400: "#c6005e",
      500: "#e12373",
      600: "#f7448a",
      700: "#ff6aa9",
      800: "#ff8fca",
      900: "#ffafe9",
    },
  },
  components: {
    Alert: {
      baseStyle: {
        borderRadius: "1rem",
      },
    },
    Divider: {
      defaultProps: {
        colorScheme: "white",
      },
      baseStyle: {
        borderColor: "white",
        borderWidth: "2px",
      },
    },
    Text: {
      baseStyle: {
        color: "black",
      },
      variants: {
        muted: {
          color: "gray.500",
        },
      },
    },
    MenuItem: {
      variants: {
        danger: {
          color: "red.500",
        },
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "semibold", // Normally, it is "semibold"
        borderRadius: "0.5rem",
        backgroundColor: "white",
        color: "black",
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        danger: {
          backgroundColor: "red.600",
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.200",
          borderWidth: "1px",
          color: "white",
        },
        "with-shadow": {
          boxShadow: "0 0 2px 2px #efdfde",
        },
        // 4. We can override existing variants
        solid: (props: StyleFunctionProps) => ({}),
        // 5. We can add responsive variants
        sm: {
          fontSize: "sm",
        },
        outline: {
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.200",
          borderWidth: "1px",
          backgroundColor: "white",
          color: "black",
          defaultProps: {},
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        variant: "outline",
      },
    },

    TableContainer: {
      baseStyle: {
        borderRadius: "1rem",
      },
    },
  },
  shadows: {
    outline: "0 0 0 3px lightgray",
  },
});

export default theme;
