import { Box, Checkbox, CheckboxGroup, Grid, Text } from "@chakra-ui/react";
import { getAllPermission_allPermissions_edges } from "graphql/queries/types/getAllPermission";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

export default function IndeterminateCheckbox({
  objectPermission,
  allowedPermissions,
  setAllowedPermissions,
  permissionGroupKey,
}: {
  objectPermission: getAllPermission_allPermissions_edges[];
  allowedPermissions: string[];
  setAllowedPermissions: Dispatch<SetStateAction<string[]>>;
  permissionGroupKey: string;
}) {
  const flattenedPermissionSet = objectPermission.map(({ node }) => node?.id);
  const allChecked = objectPermission.every(({ node }) =>
    allowedPermissions.includes(node?.id as string)
  );
  const isIndeterminate =
    objectPermission.some(({ node }) => allowedPermissions.includes(node?.id as string)) &&
    !allChecked;

  const handlePermission = (id: string) =>
    allowedPermissions.includes(id)
      ? setAllowedPermissions((prevState) => prevState.filter((allowedId) => allowedId !== id))
      : setAllowedPermissions((prevState) => [id, ...prevState]);

  const togglePermissionSet = () => {
    const allCheckboxChecked = flattenedPermissionSet.every((toBeCheckedId) =>
      allowedPermissions.includes(toBeCheckedId as string)
    );

    if (allCheckboxChecked) {
      flattenedPermissionSet.map((permissionId) =>
        setAllowedPermissions((prevState) =>
          prevState.filter((allowedId) => allowedId !== permissionId)
        )
      );
      return;
    }

    flattenedPermissionSet.map((permissionId) =>
      allowedPermissions.includes(permissionId as string)
        ? null
        : setAllowedPermissions((prevState) => [...prevState, permissionId] as string[])
    );
  };

  return (
    <CheckboxGroup colorScheme="brand">
      <Grid templateColumns="repeat(6, 1fr)" gap={6} w="100%">
        <Box>
          <Text>{permissionGroupKey}</Text>
        </Box>
        <Checkbox
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={togglePermissionSet}
        />

        {objectPermission.map((permission) => {
          if (!permission || !permission.node?.id) return null;
          const {
            node: { id },
          } = permission;
          return (
            <Checkbox
              isChecked={!!allowedPermissions.includes(id)}
              onChange={() => handlePermission(id)}
            />
          );
        })}
      </Grid>
    </CheckboxGroup>
  );
}
