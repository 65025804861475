import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      edges {
        node {
          id
          name
          email
          phone
          country {
            code
          }
          userType
          isActive
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      lastLogin
      dateJoined
      name
      email
      phone
      country {
        code
      }
      language
      timezone
      currency
      userPermissions {
        edges {
          node {
            id
            codename
            name
            contentType {
              model
              id
            }
          }
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      user {
        email
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($email: String!, $userData: UserInput) {
    createUser(email: $email, userData: $userData) {
      user {
        id
        email
        name
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($email: String!, $permissions: [String]) {
    inviteUser(email: $email, permissions: $permissions) {
      user {
        id
        email
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $userData: UserInput!) {
    updateUser(id: $id, userData: $userData) {
      user {
        id
        password
        lastLogin
        username
        dateJoined
        name
        email
        phone
        country {
          code
        }
        language
        timezone
        currency
        userType
        isActive
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET_TOKEN = gql`
  mutation RequestUserResetPassword($email: String!) {
    requestUserResetPassword(email: $email) {
      user {
        resetPasswordToken
      }
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword(
    $newPassword: String!
    $confirmPassword: String!
    $resetPasswordToken: String!
  ) {
    resetUserPassword(
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      resetPasswordToken: $resetPasswordToken
    ) {
      user {
        email
      }
    }
  }
`;

export const GET_ALL_PERMISSIONS = gql`
  query getAllPermission {
    allPermissions {
      edges {
        node {
          contentType {
            model
          }
          codename
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PERMISSIONS = gql`
  mutation UpdateUserPermissions($email: String!, $permissions: [String]) {
    updateUserPermissions(email: $email, permissions: $permissions) {
      user {
        userPermissions {
          edges {
            node {
              codename
              name
            }
          }
        }
      }
    }
  }
`;

export const TOGGLE_USER_IS_ACTIVE = gql`
  mutation ToggleUserIsActive($id: ID!) {
    toggleUserIsactive(id: $id) {
      user {
        isActive
      }
    }
  }
`;

export const ADD_USER_SIGNATURE = gql`
  mutation AddUserSignature($file: Upload!, $userId: ID!) {
    addUserSignature(file: $file, userId: $userId) {
      success
      userSignature {
        file
      }
    }
  }
`;
