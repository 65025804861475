import { Button } from "@chakra-ui/react";
import { CSVLink, CSVDownload } from "react-csv";
import { useTranslation } from "react-i18next";

const getColumnsForCsv = (columns) => columns.map(({ accessor }) => accessor);

const getDataForCsv = (data) => {
  return data.map((row) => Object.values(row.values));
};

const TableCSVDownload = ({ data, columns }) => {
  const { t } = useTranslation();
  if (!data || !columns) return <Button disabled>{t("Export to csv")}</Button>;

  const csvData = [getColumnsForCsv(columns), ...getDataForCsv(data)];
  if (!csvData) return <Button disabled>{t("Export to csv")}</Button>;

  return (
    <Button size={"sm"}>
      <CSVLink data={csvData}>{t("Export to csv")}</CSVLink>
    </Button>
  );
};

export default TableCSVDownload;
