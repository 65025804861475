import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import endsWithNumber from "helpers/endsWithNumber";
import { Trans } from "react-i18next";
import { AiOutlineRight } from "react-icons/ai";
import { BiPlusCircle } from "react-icons/bi";
import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { appRouteObjects } from "routes";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import styles from "./scss/Breadcrumb.module.scss";

interface BCrumbProps {
  path: string;
  description?: string;
  title: string;
}

const BCrumb = ({ path, description = "", title }: BCrumbProps) => {
  const breadcrumbs = useBreadcrumbs(appRouteObjects);
  const actualPage = breadcrumbs[breadcrumbs.length - 1];

  return (
    <VStack mt={6} align="left" mb={4} w="100%">
      {/* <Breadcrumb
        spacing="8px"
        separator={<Icon as={AiOutlineRight} color="gray.500" fontWeight={800} />}
      >
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <BreadcrumbItem key={match.pathname}>
            <BreadcrumbLink href="#">
              <Text fontSize="sm" color="gray.500" fontWeight={600}>
                <NavLink to={match.pathname}>{breadcrumb}</NavLink>
              </Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb> */}
      <HStack justifyContent="space-between">
        <Box>
          <Text fontSize="4xl" fontWeight={700} my={2}>
            {actualPage?.breadcrumb}
          </Text>

          <Text fontSize="sm" fontWeight={400} color="gray.500" my={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nec ornare sapien.
            Curabitur ornare mauris ac justo scelerisque, quis lacinia ligula elementum. Aliquam eu
            nibh ac nisi bibendum hendrerit ac tristique metus.
          </Text>
        </Box>
      </HStack>
    </VStack>
  );
};

export default BCrumb;
