import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import logo from "assets/logo.svg";
import { BCrumb, Footer, LanguageSelector } from "components/ui";
import { useAuth } from "contexts/AuthProvider";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiBell, BiChevronDown, BiCog, BiSearch, BiUser } from "react-icons/bi";
import { RiSettingsFill, RiUser5Fill } from "react-icons/ri";
import { ImBell } from "react-icons/im";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

import styles from "../scss/Layout.module.scss";
import {
  genericMenuEntries,
  mgmtMenuEntries,
  spinMenuEntries,
  topMenuEntries,
} from "../sidebarMenuEntries";

interface LayoutProps {
  onLocaleChange?: (locale: string) => void;
}

interface NavLinkActiveAwareProps {
  to: string;
  icon: React.ReactNode;
  name: React.ReactNode;
}

const NavLinkActiveAwareTop = ({ to, icon, name }: NavLinkActiveAwareProps) => (
  <Link
    as={NavLink}
    to={to}
    className={styles["with-sidebar__sidebar__navItem__top"]}
    _activeLink={{
      "& > button": {
        backgroundColor: "black",
        color: "white",
      },
      "& > svg": {
        color: "primaryFuchsia",
      },
      "& > p": {
        color: "white",
      },
    }}
  >
    <Button size="sm">
      {/* {icon} */}
      {name}
    </Button>
  </Link>
);
const NavLinkActiveAware = ({ to, icon, name }: NavLinkActiveAwareProps) => (
  <Link
    as={NavLink}
    to={to}
    className={styles["with-sidebar__sidebar__navItem"]}
    _activeLink={{
      borderLeft: "2px solid var(--chakra-colors-primaryFuchsia)",
      "& > svg": {
        color: "primaryFuchsia",
      },
      "& > p": {
        fontWeight: "600",
        color: "primaryFuchsia",
      },
    }}
  >
    {/* {icon} */}
    <Text fontSize="sm" fontWeight={400}>
      {name}
    </Text>
  </Link>
);

const Layout = ({ onLocaleChange }: LayoutProps) => {
  const { t } = useTranslation();
  const { user, signout } = useAuth();
  console.log({ user });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onLanguageSelect = (e: React.FormEvent<HTMLSelectElement>): void => {
    const {
      currentTarget: { value },
    } = e;
    if (!onLocaleChange) return;
    onLocaleChange(value);
  };

  console.log({ user });
  return (
    <Flex direction="column" h="100%" w="100vw">
      <Flex px={24} borderBottom="1px" borderColor="gray.100" bgColor="gray.200">
        <Box py={3}>
          <Stack direction="row" alignItems="center">
            <Avatar name={user?.name} src="https://bit.ly/broken-link" size="sm" />
            <Text fontSize="md">{user?.name}</Text>
          </Stack>
        </Box>
        <Spacer />
        <Stack direction="row" alignItems="center">
          {/* <Button variant="outline" mx={4}>
            Make an action
          </Button> */}
          <Menu>
            <MenuButton as={Button} rightIcon={<BiChevronDown />} size="sm" mx={12}>
              {t("Add new")}
            </MenuButton>
            <MenuList>
              <MenuItem>{t("Add an asset")}</MenuItem>
              <MenuItem>{t("Create a policy")}</MenuItem>
              <MenuItem>{t("Generate certificates")}</MenuItem>
            </MenuList>
          </Menu>
          <Button leftIcon={<Icon />} variant="link" mx={8} bgColor="transparent">
            Help
          </Button>
          <LanguageSelector onLanguageSelect={onLanguageSelect} />
          <HStack px={8} spacing="4">
            <IconButton
              aria-label="Search database"
              icon={<ImBell size={19} />}
              variant="ghost"
              bgColor="transparent"
              color="gray.500"
              size="xl"
              px={2}
            />{" "}
            <IconButton
              aria-label="Search database"
              icon={<RiSettingsFill size={20} />}
              variant="ghost"
              size="xl"
              bgColor="transparent"
              color="gray.500"
              px={2}
            />{" "}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Search database"
                icon={<RiUser5Fill size={20} />}
                variant="ghost"
                bgColor="transparent"
                color="gray.500"
                size="xl"
                px={2}
              />
              <MenuList>
                <MenuItem onClick={(e) => signout(() => navigate("/login"))}>
                  {t("Log out")}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
      </Flex>
      <HStack
        spacing="24px"
        px={20}
        py={3}
        borderBottom="1px"
        borderColor="gray.100"
        bgColor="gray.100"
      >
        {topMenuEntries &&
          topMenuEntries
            .slice(0, 4)
            .map(({ to, icon, name }) => (
              <NavLinkActiveAwareTop key={to} to={to} icon={icon} name={name} />
            ))}
      </HStack>

      <div className={styles.wrapper}>
        <div className={`"bp4-dark" ${styles["with-sidebar"]}`}>
          {/* <!-- sidebar --> */}
          <Box pl={20}>
            <Stack direction="column" spacing="2" mt={20} borderLeft="1px" borderColor="gray.100">
              {mgmtMenuEntries &&
                pathname.includes("mgmt") &&
                mgmtMenuEntries.map(({ to, icon, name }) => (
                  <NavLinkActiveAware key={to} to={to} icon={icon} name={name} />
                ))}

              {spinMenuEntries &&
                pathname.includes("spin") &&
                spinMenuEntries.map(({ to, icon, name }) => (
                  <NavLinkActiveAware to={to} key={to} icon={icon} name={name} />
                ))}

              {genericMenuEntries &&
                pathname.includes("/o/") &&
                genericMenuEntries.map(({ to, icon, name }) => (
                  <NavLinkActiveAware to={to} icon={icon} key={to} name={name} />
                ))}
            </Stack>
          </Box>

          <div>
            {/* <!-- non-sidebar --> */}
            <Box px={12} py={6}>
              <HStack spacing="24px" borderBottom="1px" borderColor="gray.100">
                <BCrumb path={pathname} title="test" />
              </HStack>
              <Outlet />
            </Box>
          </div>
        </div>
      </div>
      <Footer />
    </Flex>
  );
};

export default Layout;
