import { gql } from "@apollo/client";

export const GET_TOKEN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      payload
      refreshToken
      refreshExpiresIn
      user {
        name
        country {
          code
        }
        currency
        dateJoined
        email
        id
        isActive
        isStaff
        isSuperuser
        language
        lastLogin
        phone
        timezone
        userType
        username
        uuid
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
