import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FieldGroup } from "components/ui";
import { useFormik } from "formik";
import {
  AddUserSignature,
  AddUserSignatureVariables,
} from "graphql/queries/types/AddUserSignature";
import { ChangePassword, ChangePasswordVariables } from "graphql/queries/types/ChangePassword";
import {
  getAllPermission,
  getAllPermission_allPermissions_edges,
  getAllPermission_allPermissions_edges_node,
} from "graphql/queries/types/getAllPermission";
import { GetUserById, GetUserByIdVariables } from "graphql/queries/types/GetUserById";
import { UpdateUser, UpdateUserVariables } from "graphql/queries/types/UpdateUser";
import {
  UpdateUserPermissions,
  UpdateUserPermissionsVariables,
} from "graphql/queries/types/UpdateUserPermissions";
import {
  ADD_USER_SIGNATURE,
  CHANGE_PASSWORD,
  GET_ALL_PERMISSIONS,
  GET_USER,
  UPDATE_PERMISSIONS,
  UPDATE_USER,
} from "graphql/queries/users.gql";
import countryCodes from "helpers/countryCodes.json";
import currencies from "helpers/currencies.json";
import { Dictionary } from "lodash";
import groupBy from "lodash/groupBy";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiFile } from "react-icons/bi";
import { Navigate, useParams } from "react-router-dom";
import { UserInput } from "../../../../types/globalTypes";
import PermissionsForm from "../PermissionsForm";

type ExtractedInput = Pick<UpdateUserVariables, "id"> | UserInput;

const UsersEditPage = () => {
  const { userId = "" } = useParams();
  const { t } = useTranslation();
  const signatureUploadRef = useRef<HTMLInputElement>(null);

  const [formattedPermissions, setFormattedPermissions] =
    useState<Dictionary<(getAllPermission_allPermissions_edges | null)[]>>();

  const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

  const { data, loading, error } = useQuery<GetUserById, GetUserByIdVariables>(GET_USER, {
    variables: { id: userId },
  });

  useEffect(() => {
    if (!data?.user) return;
    const { user } = data;

    if (!user.userPermissions) return;

    const flattenedPermissions = user.userPermissions.edges.map((edge) => edge?.node?.id ?? "");
    setAllowedPermissions(flattenedPermissions);
  }, [data]);

  useEffect(() => {
    console.log(allowedPermissions);
  }, [allowedPermissions]);

  const {
    data: allPermissions,
    loading: allPermissionsLoading,
    error: allPermissionsError,
  } = useQuery<getAllPermission>(GET_ALL_PERMISSIONS, {
    onCompleted(permissionData) {
      if (!permissionData?.allPermissions?.edges) return;

      const {
        allPermissions: { edges },
      } = permissionData;

      const groupedPermissions = groupBy(
        edges,
        (permission) => permission?.node?.contentType.model
      );
      console.log({ groupedPermissions });
      setFormattedPermissions(groupedPermissions);
    },
  });

  const [
    changePassword,
    { data: changePasswordData, loading: changePasswordLoading, error: changePasswordError },
  ] = useMutation<ChangePassword, ChangePasswordVariables>(CHANGE_PASSWORD);

  const [updateUser, { data: updateUserData, loading: updateUserLoading, error: updateUserError }] =
    useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER);

  const [
    updateUserPermissions,
    {
      data: updateUserPermissionsData,
      loading: updateUserPermissionsLoading,
      error: updateUserPermissionsError,
    },
  ] = useMutation<UpdateUserPermissions, UpdateUserPermissionsVariables>(UPDATE_PERMISSIONS);

  const [
    addUserSignature,
    { data: addUserSignatureData, loading: addUserSignatureLoading, error: addUserSignatureError },
  ] = useMutation<AddUserSignature, AddUserSignatureVariables>(ADD_USER_SIGNATURE);

  const formikUpdateUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.user?.name,
      phone: data?.user?.phone,
      country: data?.user?.country,
      // language: data?.user?.language,
      // timezone: data?.user?.timezone,
      currency: data?.user?.currency,
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      await updateUser({
        variables: {
          id: data?.user?.id ?? "",
          userData: {
            ...values,
            country: values?.country?.code,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          },
        },
      });
    },
  });

  const formikChangePassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: data?.user?.email || "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      await changePassword({
        variables: {
          ...values,
        },
      });
    },
  });

  const formikUpdateSignature = useFormik({
    enableReinitialize: true,
    initialValues: {
      signature: "",
    },
    onSubmit: async (values) => {
      console.log(values, "TEST");
      await addUserSignature({
        variables: {
          file: values.signature,
          userId: userId ?? "",
        },
      });
    },
  });

  const formikUpdatePermissions = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...allPermissions,
    },
    onSubmit: async () => {
      await updateUserPermissions({
        variables: {
          email: data?.user?.email ?? "",
          permissions: allowedPermissions,
        },
      });
    },
  });

  useEffect(() => console.log(formikUpdateUser.values), [formikUpdateUser]);
  if (loading) return null;
  if (error) return <p>{error.toString()}</p>;

  // TODO: add route paths to something that stores them (routes.ts?), we mustn't write strings on navigate arguments
  if (!userId || !data || !data.user) return <Navigate to="/o/users" replace />;

  const { user } = data;

  return (
    <Box maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}>
        <form id="updateUser" onSubmit={formikUpdateUser.handleSubmit}>
          <FieldGroup title={t("Personal Info")} subtitle={t("Lorem ipsum dolor sit amet")}>
            <VStack
              width="full"
              spacing="6"
              bgColor="white"
              p="8"
              rounded="lg"
              shadow="sm"
              align="left"
            >
              <Text variant="muted">
                {t("User created at ")}
                {user.dateJoined}
              </Text>
              <Text variant="muted">
                {t("User last login at")}
                {user.lastLogin}
              </Text>
              {updateUserError && (
                <Alert status="error">
                  <>
                    <AlertIcon />
                    {updateUserError.message}
                  </>
                </Alert>
              )}
              {updateUserData && (
                <Alert status="success">
                  <>
                    <AlertIcon />
                    {t("Your account has been updated!")}
                  </>
                </Alert>
              )}
              <FormControl>
                <FormLabel htmlFor="email">{t("Email Address")}</FormLabel>
                <Input
                  width="auto"
                  id="email"
                  name="email"
                  type="email"
                  disabled
                  value={user.email}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="name">{t("Name")}</FormLabel>
                <Input
                  width="auto"
                  id="name"
                  name="name"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.name}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="phone">{t("Phone number")}</FormLabel>
                <Input
                  width="auto"
                  id="phone"
                  name="phone"
                  type="phone"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.phone ?? ""}
                />
              </FormControl>

              <Divider borderColor="gray.100" />
              <HStack width="full">
                <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                  {t("Save Changes")}
                </Button>
                <Button size="sm" variant="danger">
                  {t("Cancel")}
                </Button>
              </HStack>
            </VStack>
          </FieldGroup>

          <FieldGroup
            title={t("Internationalization options")}
            subtitle={t("Lorem ipsum dolor sit amet")}
          >
            <Stack
              direction="column"
              spacing="6"
              align="center"
              width="full"
              bgColor="white"
              p="8"
              rounded="lg"
              shadow="sm"
            >
              <FormControl>
                <FormLabel htmlFor="country">{t("Country")}</FormLabel>
                <Select
                  width="auto"
                  id="country"
                  name="country.code"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values?.country?.code ?? ""}
                >
                  {countryCodes.map(({ name, code }) => (
                    <option value={code}>{name}</option>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl>
                <FormLabel htmlFor="language">{t("Language")}</FormLabel>
                <Input
                  width="auto"
                  id="language"
                  name="language"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.language ?? ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="timezone">{t("Timezone")}</FormLabel>
                <Input
                  width="auto"
                  id="timezone"
                  name="timezone"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.timezone ?? ""}
                />
              </FormControl> */}
              <FormControl>
                <FormLabel htmlFor="currency">{t("Currency")}</FormLabel>
                <Select
                  width="auto"
                  id="currency"
                  name="currency"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.currency ?? ""}
                >
                  {currencies.map(({ name, value }) => (
                    <option value={value} selected={formikUpdateUser.values.currency === value}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Divider borderColor="gray.100" />
              <HStack width="full">
                <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                  {t("Save Changes")}
                </Button>
                <Button size="sm" variant="danger">
                  {t("Cancel")}
                </Button>
              </HStack>
            </Stack>
          </FieldGroup>
        </form>
        <form id="updateSignature" onSubmit={formikUpdateSignature.handleSubmit}>
          <FieldGroup title={t("Signature")} subtitle={t("Lorem ipsum dolor sit amet")}>
            <Stack
              direction="column"
              spacing="6"
              align="center"
              width="full"
              bgColor="white"
              p="8"
              rounded="lg"
              shadow="sm"
            >
              <FormControl isRequired>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={BiFile} />
                  </InputLeftElement>
                  <input
                    type="file"
                    onChange={(e) => {
                      if (!e.currentTarget.files) return;
                      formikUpdateSignature
                        .setFieldValue("signature", e?.currentTarget?.files[0])
                        .then((evt) => console.log(evt))
                        .catch((err) => console.log(err));
                    }}
                    accept="image/*"
                    name="signature"
                    ref={signatureUploadRef}
                    style={{ display: "none" }}
                  />
                  <Input
                    placeholder="Your file ..."
                    onClick={(): void => {
                      if (!signatureUploadRef || !signatureUploadRef.current) return;
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      signatureUploadRef.current.click();
                    }}
                    // onChange={(e) => {}}
                    readOnly
                    value={formikUpdateSignature.values?.signature}
                  />
                </InputGroup>
              </FormControl>
              <HStack width="full">
                <Button
                  size="sm"
                  isLoading={changePasswordLoading}
                  loadingText="Loading"
                  type="submit"
                >
                  {t("Upload new signature")}
                </Button>
                <Button size="sm" variant="danger">
                  {t("Remove old signature")}
                </Button>
              </HStack>
            </Stack>
          </FieldGroup>
        </form>
        <form id="updateUser" onSubmit={formikChangePassword.handleSubmit}>
          <FieldGroup title={t("Change Password")} subtitle="Lorem ipsum dolor sit amet">
            <VStack width="full" spacing="6" bgColor="white" p="8" rounded="lg" shadow="sm">
              {changePasswordError && (
                <Alert status="error">
                  <>
                    <AlertIcon />
                    {changePasswordError.message}
                  </>
                </Alert>
              )}
              {changePasswordData && (
                <Alert status="success">
                  <>
                    <AlertIcon />
                    {t("Your password has been updated")}
                  </>
                </Alert>
              )}

              <FormControl isRequired>
                <FormLabel htmlFor="oldPassword">{t("Old Password")}</FormLabel>
                <Input
                  width="auto"
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  onChange={formikChangePassword.handleChange}
                  value={formikChangePassword.values.oldPassword}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="newPassword">{t("New password")}</FormLabel>
                <Input
                  width="auto"
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  onChange={formikChangePassword.handleChange}
                  value={formikChangePassword.values.newPassword}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="confirmPassword">{t("Confirm Password")}</FormLabel>
                <Input
                  width="auto"
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formikChangePassword.handleChange}
                  value={formikChangePassword.values.confirmPassword}
                />
              </FormControl>
              <Divider borderColor="gray.100" />
              <HStack width="full">
                <Button
                  size="sm"
                  isLoading={changePasswordLoading}
                  loadingText="Loading"
                  type="submit"
                >
                  {t("Save Changes")}
                </Button>
                <Button size="sm" variant="danger">
                  {t("Cancel")}
                </Button>
              </HStack>
            </VStack>
          </FieldGroup>
        </form>
        <PermissionsForm
          handleSubmit={formikUpdatePermissions.handleSubmit}
          success={!!updateUserPermissionsData}
          permissions={formattedPermissions}
          loading={updateUserPermissionsLoading}
          error={updateUserPermissionsError}
          allowedPermissions={allowedPermissions}
          setAllowedPermissions={setAllowedPermissions}
        />
      </Stack>
    </Box>
  );
};

export default UsersEditPage;
