import { Box, Select, Text } from "@chakra-ui/react";
import LOCALES from "i18n/locales";

const languages = [
  { name: "English", code: LOCALES.ENGLISH, flag: "🇬🇧" },
  { name: "Français", code: LOCALES.FRENCH, flag: "🇫🇷" },
  { name: "Deutsche", code: LOCALES.GERMAN, flag: "🇩🇪" },
  { name: "Italian", code: LOCALES.ITALIAN, flag: "🇮🇹" },
  { name: "Spanish", code: LOCALES.SPANISH, flag: "🇪🇸" },
];

interface LanguageSelectorProps {
  onLanguageSelect: (e: React.FormEvent<HTMLSelectElement>) => void;
}

const LanguageSelector = ({ onLanguageSelect }: LanguageSelectorProps) => (
  <Box px={4}>
    <Select onChange={onLanguageSelect} bgColor="transparent" size="sm">
      {languages.map(({ name, code, flag }) => (
        <option key={code} value={code}>
          {flag} {name}
        </option>
      ))}
    </Select>
  </Box>
);

export default LanguageSelector;
