import { useMemo } from "react";
import { Select } from "@chakra-ui/react";
import { Trans } from "react-i18next";

function DropdownFilter({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      width="auto"
      bgColor="white"
      shadow={"sm"}
      size="sm"
      borderRadius="md"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">
        <Trans>Choose {Header}</Trans>
      </option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}

export default DropdownFilter;
